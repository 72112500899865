import { useCurrentCompany } from '@swibeco/security';

const useBenefitsManagerRedirectUrl = () => {
  const { company } = useCurrentCompany();
  const currentUrl = window.location.origin;
  let modifiedBenefitsManagerUrl;
  if (!company) {
    return 'https://benefitsmanager.swibeco.ch/login-email';
  }
  if (!company.domain) {
    // extract the first part of the url (before the first dot)
    // and replace it with benefitsmanager
    const urlParts = currentUrl.split('.');
    if (urlParts.length < 2) {
      return '';
    }
    modifiedBenefitsManagerUrl = currentUrl.replace(
      `${urlParts[0]}.`,
      'https://benefitsmanager.'
    );
  } else {
    modifiedBenefitsManagerUrl = currentUrl.includes('app.')
      ? currentUrl.replace('app.', 'benefitsmanager.')
      : currentUrl.replace(`${company!.domain!}.`, 'benefitsmanager.');
  }

  return `${modifiedBenefitsManagerUrl}/login-email`;
};

export default useBenefitsManagerRedirectUrl;
